.formContainer {
  display: flex;
  flex-direction: column;
  padding: 50px;


}

@media (min-width: 768px) {
  .formContainer {
    width: 500px;
  }
}

.textFieldContainer {
  margin-bottom: 20px;
}

.saveContainer {
  width: 100%;
  height: 100%;
}